<!-- 首页-行业解决方案 -->
<template>
	<div class="IndustrySolutions-warp">
		<div class="title">
			<span class="title-text">行业解决方案</span>
		</div>

		<div class="industrySolutions" v-if="!isMobileEnd">
			<div class="tag-container">
				<el-tabs v-model="activeName">
					<el-tab-pane
						v-for="(tag, index) in fieldList"
						:key="index"
						:name="index.toString()"
					>
						<template slot="label">
							<span class="tag-label">{{ tag.briefName }}</span>
						</template>
					</el-tab-pane>
				</el-tabs>
			</div>
		</div>
		<div
			class="container"
			v-if="!isMobileEnd"
			v-lazy:background-image="homeSolutionBgImg"
		>
			<div class="card">
				<div class="btn-left" @click="handleField(0)">
					<i class="iconfont icon-zuo-copy"></i>
				</div>
				<div class="card-img">
					<img :src="visibleTagImg" alt="tag.name" />
				</div>
				<div class="card-text">
					<div class="card-title">
						{{ visibleTagName }}
					</div>
					<div class="card-desc">
						{{ visibleTagDesc }}
					</div>
					<div :class="['card-list', listClass]">
						<p
							v-for="item in solutionList"
							:key="item.id"
							@click="handleSolution(item.id)"
							class="card-list-p"
						>
							<i class="iconfont icon-yuanquan"></i
							>{{ item.name }}
						</p>
					</div>
				</div>
				<div class="btn-right" @click="handleField(1)">
					<i class="iconfont icon-xiala-shang-copy"></i>
				</div>
			</div>
		</div>
		<div v-if="isMobileEnd">
			<MobileIndustrySolutions />
		</div>
	</div>
</template>

<script>
import MobileIndustrySolutions from './MobileIndustrySolutions.vue'
import { getHomeFieldList, getHomePlanList } from '@/network/home/index'
export default {
	name: 'IndustrySolutions',
	data() {
		return {
			homeSolutionBgImg: require('@/assets/img/home/homeSolutionBg.png'),
			solutionList: [],
			activeName: '0',
			fieldList: [],
		}
	},
	computed: {
		isMobileEnd() {
			return this.$store.state.isMobileEnd
		},
		visibleTagImg() {
			let num = parseInt(this.activeName)
			return this.fieldList[num]?.coverImg
		},
		visibleTagName() {
			let num = parseInt(this.activeName)
			return this.fieldList[num]?.name
		},
		visibleTagDesc() {
			let num = parseInt(this.activeName)
			return this.fieldList[num]?.describes
		},
		listClass() {
			return this.solutionList.length > 6
				? 'double-column'
				: 'single-column'
		},
	},
	watch: {
		activeName(newVal) {
			const newIndex = parseInt(newVal)
			let id = this.fieldList[newIndex].id
			this._getHomePlanList(id)
		},
	},
	created() {
		this._getHomeFieldList()
	},
	mounted() {},
	methods: {
		handleField(type) {
			if (type) {
				//下一个
				let newIndex = parseInt(this.activeName) + 1
				if (newIndex === this.fieldList.length) {
					newIndex = 0
				}
				let id = this.fieldList[newIndex].id
				this._getHomePlanList(id)
				this.activeName = newIndex.toString()
			} else {
				//上一个
				let newIndex = parseInt(this.activeName) - 1
				if (newIndex < 0) {
					newIndex = this.fieldList.length - 1
				}
				let id = this.fieldList[newIndex].id
				this._getHomePlanList(id)
				this.activeName = newIndex.toString()
			}
		},
		handleSolution(id) {
			//之后用id
			if (id) {
				this.$router.push({
					path: '/solution',
					query: {
						id,
					},
				})
			}
		},
		async _getHomeFieldList() {
			try {
				const res = await getHomeFieldList()
				if (res.data.code == 200) {
					this.fieldList = res.data.data.list.map((item) => {
						let obj = Object.assign(item, {
							coverImg: window.baseUrl + item.coverImg,
						})
						return obj
					})
					if (this.fieldList.length > 0) {
						this._getHomePlanList(this.fieldList[0].id)
					}
				}
			} catch (error) {
				console.error('获取字段列表失败', error)
			}
		},
		async _getHomePlanList(id) {
			try {
				const res = await getHomePlanList(id)
				if (res.data.code == 200) {
					this.solutionList = res.data.data.list
				}
			} catch (error) {
				console.error('获取方案列表失败', error)
			}
		},
	},
	components: {
		MobileIndustrySolutions,
	},

	destroyed() {},
}
</script>
<style lang="scss" scoped>
.IndustrySolutions-warp {
	margin-bottom: 80px;
	background-color: #f6f8fb;
	.industrySolutions {
		display: flex;
		justify-content: center;
		.container {
		}
	}
}
.tag-container {
	display: flex;
	align-items: center;
	::v-deep .el-tabs__header {
		margin: 0;
	}
	::v-deep .el-tabs__nav-scroll {
		max-width: 1080px;
	}
	::v-deep .el-tabs__nav-wrap::after {
		background-color: transparent;
	}
	::v-deep .el-tabs__nav-next,
	::v-deep .el-tabs__nav-prev {
		line-height: 20px;
		&:hover {
			color: #1d67e8;
		}
	}
	::v-deep .el-tabs__item {
		font-weight: 400;
		font-size: 16px;
		color: #333333;
		line-height: 23px;
		padding: 0;
		.tag-label {
			padding: 0 20px;
			box-sizing: border-box;
		}
	}
	::v-deep .el-tabs__item.is-active {
		color: #1d67e8;
	}
	::v-deep .el-tabs__item:hover {
		color: #1d67e8;
	}
	::v-deep .el-tabs__active-bar {
		height: 3px;
		background-color: #1d67e8;
	}
}
.tags {
	display: flex;
	overflow-x: auto;
}
.tag {
	margin-right: 5px;
	padding: 5px;
	background-color: #f0f0f0;
	border-radius: 5px;
}

.container {
	width: 100%;
	// height: 578px;
	padding: 72px 0;
	background-size: cover;
	background-repeat: no-repeat;
	background-position: center center;
	display: flex;
	justify-content: center;
	align-items: center;
	.card {
		display: flex;
		align-items: center;
		border-radius: 16px;
		.card-img {
			width: 580px;
			height: 434px;
			border-radius: 16px 0 0 16px;
			overflow: hidden;
		}
		.card-text {
			width: 570px;
			height: 434px;
			background: #ffffff;
			box-shadow: 0px 4px 8px 0px rgba(51, 51, 51, 0.08);
			display: flex;
			flex-direction: column;
			// justify-content: center;
			// align-items: center;
			padding: 70px 16px 0 32px;
			box-sizing: border-box;
			border-radius: 0 16px 16px 0;
			overflow: hidden;
			.card-title {
				font-weight: 600;
				font-size: 32px;
				color: #333333;
				line-height: 46px;
				margin-bottom: 8px;
			}
			.card-desc {
				font-weight: 400;
				font-size: 14px;
				color: #666666;
				line-height: 22px;
				margin-bottom: 20px;
			}
			.card-list {
				display: flex;
				flex-wrap: wrap;
				.card-list-p {
					font-weight: 400;
					font-size: 16px;
					color: #333333;
					line-height: 22px;
					margin: 8px 0;
					cursor: pointer;
					i {
						display: inline-block;
						font-size: 14px;
						color: #1d67e8;
						margin-right: 3px;
					}
					&:hover {
						color: #1d67e8;
					}
				}
			}
			.single-column .card-list-p {
				flex: 1 0 100%; /* 每个元素占据大约 45% 的宽度 */
			}

			.double-column .card-list-p {
				flex: 1 0 45%; /* 每个元素占据大约 45% 的宽度 */
				box-sizing: border-box; /* 确保内边距和边框不影响宽度 */
			}
		}
		.btn-left,
		.btn-right {
			width: 48px;
			height: 48px;
			border-radius: 50%;
			background: #ffffff;
			box-shadow: 0px 4px 8px 0px rgba(51, 51, 51, 0.08);
			text-align: center;
			line-height: 48px;
			font-size: 24px;
			margin: 0 20px;
			cursor: pointer;
			i {
				font-size: 24px;
			}
			&:hover {
				background-color: rgba(102, 102, 102, 0.1);
			}
		}
	}
}

.title {
	width: 100%;
	margin-bottom: 50px;
	text-align: center;
	.title-text {
		font-size: 36px;
		font-weight: 600;
		color: #333333;
		line-height: 52px;
		display: block;
	}
}

@media screen and (max-width: 1200px) {
}
@media screen and (max-width: 992px) {
}
@media screen and (max-width: 768px) {
	.IndustrySolutions-warp {
		background-color: #ffffff;
		margin-bottom: 32px;
		.title {
			margin-bottom: 24px;
			.title-text {
				height: 24px;
				font-weight: 600;
				font-size: 24px;
				color: #333333;
				line-height: 24px;
			}
		}
	}
}
@media screen and (max-width: 520px) {
}
</style>
