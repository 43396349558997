<!-- 首页-产品中心 -->
<template>
	<div class="productCenter-warp">
		<div class="title">
			<span class="title-text">产品中心</span>
		</div>
		<div class="productCenter-warp-center" v-if="!isMobileEnd">
			<div class="container">
				<el-tabs tab-position="left" type="card">
					<el-tab-pane
						v-for="(item, index) in seriesList"
						:key="index"
					>
						<template slot="label">
							<div
								class="tab-label"
								@click="_getHomeProductList(item.seriesId)"
							>
								<div class="tab-label-img">
									<img
										v-lazy="item.seriesIcon"
										:alt="item.seriesName"
									/>
								</div>
								<div class="tab-label-text">
									<div class="label-name">
										{{ item.seriesName }}
									</div>
									<div class="label-desc">
										{{ item.seriesDescribes }}
									</div>
								</div>
							</div>
						</template>
						<div class="product-list">
							<div
								class="product-item"
								v-for="(product, z) in productInfo"
								:key="z"
								@click="clickProduct(product.id)"
							>
								<div
									class="product-img"
									v-lazy:background-image="
										product.unselectedCoverImg
									"
								></div>
								<div class="product-name">
									{{ product.name }}
								</div>
								<div class="product-sketch">
									{{ product.sketch }}
								</div>
								<div class="product-tags">
									<div
										class="product-tag"
										v-for="(tag, i) in product.tags"
										:key="i"
									>
										<span v-show="tag.tag">
											{{ tag.tag }}
										</span>
									</div>
								</div>
								<div class="product-btn">了解更多></div>
							</div>
						</div>
					</el-tab-pane>
				</el-tabs>
			</div>
		</div>
		<div class="productCenter-768-center" v-else>
			<div class="container">
				<el-tabs type="card">
					<el-tab-pane
						v-for="(item, index) in seriesList"
						:key="index"
					>
						<template slot="label">
							<div
								class="tab-label"
								@click="_getHomeProductList(item.seriesId)"
							>
								<div class="tab-label-img">
									<img
										v-lazy="item.seriesIcon"
										:alt="item.seriesName"
									/>
								</div>
								<div class="tab-label-text">
									{{ item.seriesName }}
								</div>
							</div>
						</template>
						<div class="product-list">
							<el-scrollbar
								style="width: 100%; height: 100%"
								wrap-style="overflow-y:auto;"
							>
								<div class="product-list-warp">
									<div
										class="product-item"
										v-for="(product, z) in productInfo"
										:key="z"
										@click="clickProduct(product.id)"
									>
										<div
											class="product-img"
											v-lazy:background-image="
												product.unselectedCoverImg
											"
										></div>
										<div class="product-name">
											{{ product.name }}
										</div>
										<div class="product-sketch">
											{{ product.sketch }}
										</div>
										<div class="product-btn">了解更多></div>
									</div>
								</div>
							</el-scrollbar>
						</div>
					</el-tab-pane>
				</el-tabs>
			</div>
		</div>
	</div>
</template>

<script>
import { getHomeProductList } from '@/network/home/index'
export default {
	name: 'ProductCenter',
	data() {
		return {
			productInfo: [],
		}
	},
	watch: {},
	created() {
		// if (window.screen.width <= 768) {
		// 	this.params.pageNum = 3
		// }
	},
	mounted() {
		let seriesList = this.$store.state.seriesList
		if (seriesList && seriesList.length > 0) {
			let id = seriesList[0].seriesId
			this._getHomeProductList(id)
		} else {
			// 如果 seriesList 没有数据，可以监听 store 中的数据变化
			this.$store.watch(
				(state) => state.seriesList,
				(newSeriesList) => {
					if (newSeriesList && newSeriesList.length > 0) {
						let id = newSeriesList[0].seriesId
						this._getHomeProductList(id)
					}
				}
			)
		}
	},
	methods: {
		clickProduct(id) {
			if (id) {
				this.$router.push({
					path: '/product',
					query: {
						id,
					},
				})
			}
		},
		_getHomeProductList(id) {
			getHomeProductList(id)
				.then((res) => {
					if (res.data.code == 200) {
						const { list } = res.data.data
						this.productInfo = list.map((item) => {
							let obj = Object.assign(item, {
								unselectedCoverImg:
									window.baseUrl + item.unselectedCoverImg,
							})
							return obj
						})
					}
				})
				.catch((error) => {
					console.error('获取产品列表失败', error)
				})
		},
	},
	components: {},
	computed: {
		isMobileEnd() {
			return this.$store.state.isMobileEnd
		},
		seriesList() {
			return this.$store.state.seriesList
		},
	},
	destroyed() {},
}
</script>
<style lang="scss" scoped>
.productCenter-warp {
	// margin-bottom: 80px;
	background-color: #f6f8fb;
	padding-top: 4.1667vw;
	padding-bottom: 80px;
	// &-center {
	// 	min-height: 3.2rem;
	// }
}
.title {
	width: 100%;
	margin-bottom: 50px;
	text-align: center;
	.title-text {
		// height: 3.125vw;
		font-size: 36px;
		font-weight: 600;
		color: #333333;
		line-height: 52px;
		display: block;
	}
	// 1、调整浏览器分辨率
	// a: 分辨率长宽比例为1.778，为保证最佳显示状态，可全屏显示。1920*1080切换成1366*768后，只是像素密度降低,比例不变
	// b: 分辨率长宽比例不为1.778，为保障最佳视觉效果，可设置为高度撑满，宽度进行等比缩放占有区域，居中显示，两边会留白
	// 2、拖拽浏览器窗口
	// 窗口大小为1920*1080，通过浏览器拖拽窗口，使浏览器窗口宽度达到1366*768.通过拖拽，屏幕像素密度不发生变化，只是浏览器的可视窗口在宽度上减少
}
.productCenter-warp-center {
	display: flex;
	justify-content: center;
	.container {
		width: 1275px;
		// height: 29.1667vw;
		background: linear-gradient(
			270deg,
			rgba(199, 235, 255, 0.1) 0%,
			#dbebff 100%
		);
		border-radius: 16px;
		overflow: hidden;
		::v-deep .el-tabs__nav {
			border: none;
		}
		::v-deep .el-tabs__nav-wrap {
			height: 564px;
			padding: 0;
			.el-tabs__nav-scroll {
				overflow-y: auto;
			}
		}
		::v-deep .el-tabs__nav-prev,
		::v-deep .el-tabs__nav-next {
			display: none;
		}

		::v-deep .el-tabs__item {
			height: auto;
			border-right: none;
			background-color: #ffffff;
			&:first-child {
				border-right: none;
			}
			&:last-child {
				border-bottom: none;
			}
			.tab-label {
				width: 294px;
				//height: 5.7813vw;
				padding: 30px 0;
				display: flex;
				align-items: center;
				.tab-label-img {
					width: 52px;
					height: 52px;
					margin-left: 24px;
					margin-right: 10px;
					img {
						width: 100%;
						height: 100%;
					}
				}
				.tab-label-text {
					.label-name {
						font-weight: 600;
						font-size: 16px;
						line-height: 23px;
						color: #333333;
					}
					.label-desc {
						font-size: 14px;
						line-height: 20px;
						color: #666666;
					}
				}
			}
		}
		::v-deep .el-tabs--left.el-tabs--card .el-tabs__item.is-left.is-active {
			border-right: none;
			border-color: transparent;
			background-color: transparent;
			background-image: url('../../../assets/img/home/homeProductItenBg2.png');
			background-size: cover;
			background-repeat: no-repeat;
			background-position: center center;
			.tab-label {
				// height: 5.8333vw;
			}
		}
		::v-deep .el-tabs__content {
			.el-tab-pane {
				height: 564px;
				overflow-y: auto;
			}
		}
		.product-list {
			padding: 16px 0;
			display: grid;
			grid-template-columns: repeat(3, 295px);
			// grid-template-rows: repeat(3, 168px);
			gap: 12px; /* 格子间隔 */
			.product-item {
				// width: 16.4063vw;
				// height: 8.75vw;
				height: 168px;
				background: #ffffff;
				color: #333333;
				border-radius: 16px;
				overflow: hidden;
				padding: 20px;
				box-sizing: border-box;
				background-size: cover;
				background-repeat: no-repeat;
				background-position: center center;
				position: relative;
				z-index: 0;
				cursor: pointer;
				.product-img {
					visibility: hidden;
					width: 100%;
					height: 100%;
					position: absolute;
					top: 0;
					left: 0;
					background-size: cover;
					background-repeat: no-repeat;
					background-position: center center;
					z-index: -1;
				}
				.product-name {
					font-weight: 600;
					font-size: 16px;
					line-height: 24px;
				}
				.product-sketch {
					font-weight: 400;
					font-size: 14px;
					color: #666666;
					line-height: 20px;
					white-space: nowrap;
					overflow: hidden;
					text-overflow: ellipsis;
					margin: 8px 0;
				}
				.product-tags {
					display: flex;
					.product-tag {
						height: 20px;
						border-radius: 0.2083vw;
						overflow: hidden;
						font-size: 12px;
						background-color: #f2f5f7;
						line-height: 20px;
						margin-right: 8px;
						span {
							display: inline-block;
							width: 100%;
							height: 100%;
							color: #666666;
							padding: 0 6px;
							box-sizing: border-box;
						}
					}
				}
				.product-btn {
					font-weight: 400;
					font-size: 14px;
					line-height: 20px;
					text-align: right;
					margin-top: 30px;
					// position: absolute;
					// right: 20px;
					// bottom: 20px;
				}
				&:hover {
					color: #fff;
					.product-img {
						visibility: initial;
					}
					.product-sketch {
						color: #ffffff;
					}
					.product-tags .product-tag {
						background-color: rgba(255, 255, 255, 0.2);
						span {
							color: #ffffff;
						}
					}
				}
			}
		}
	}
}
::v-deep .el-tabs--card > .el-tabs__header {
	border-bottom: none;
}

.productCenter-768-center {
	width: calc(100% - 24px);
	// height: 542px;
	padding: 0 12px;
	.container {
		width: 100%;
		height: 100%;
		background-color: #e2efff;
		::v-deep .el-tabs--card > .el-tabs__header {
			margin: 0;
			.el-tabs__nav-wrap {
				padding: 0;
			}
		}
		::v-deep .el-tabs__nav-prev,
		::v-deep .el-tabs__nav-next {
			display: none;
		}
		::v-deep .el-tabs__nav {
			border: none;
			width: 100%;
		}
		::v-deep .el-tabs__item {
			width: 20%;
			min-width: 70px;
			padding: 0;
			height: auto;
			border-right: none;
			border-color: transparent;
			background-color: #ffffff;
			border-bottom: none;
			padding-left: 0 !important;
			&:last-child {
				border-bottom: none;
				padding-right: 0;
			}
			.tab-label {
				width: 70px;
				height: 32px;
				display: flex;
				justify-content: center;
				align-items: center;
				.tab-label-img {
					width: 20px;
					height: 20px;
					display: flex;
					justify-content: center;
					align-items: center;
					overflow: hidden;
					margin-right: 2px;
					img {
						width: 100%;
						height: 100%;
						object-fit: cover;
					}
				}
				.tab-label-text {
					font-weight: 500;
					font-size: 14px;
					color: #333333;
					line-height: 12px;
				}
			}
		}
		::v-deep .el-tabs--card > .el-tabs__header .el-tabs__item.is-active {
			border-color: transparent;
			background-color: transparent;
		}
		.product-list {
			height: auto;
			padding: 16px 8px;
			::v-deep .el-scrollbar__bar {
				display: none;
			}
			.product-list-warp {
				// width: 331px;
			}
			.product-item {
				// width: 16.4063vw;
				// height: 8.75vw;
				width: calc(100% - 6px);
				height: 64px;
				background-color: #ffffff;
				color: #333333;
				border-radius: 8px;
				padding: 12px 16px;
				box-sizing: border-box;
				margin-bottom: 8px;
				position: relative;
				cursor: pointer;
				.product-img {
					visibility: hidden;
					width: 100%;
					height: 100%;
					position: absolute;
					top: 0;
					left: 0;
					background-size: cover;
					background-repeat: no-repeat;
					background-position: center center;
					z-index: -1;
				}
				.product-name {
					font-weight: 500;
					font-size: 16px;
					color: #333333;
					line-height: 23px;
				}
				.product-sketch {
					max-width: 234px;
					font-weight: 400;
					font-size: 12px;
					color: #666666;
					line-height: 17px;
					white-space: nowrap;
					overflow: hidden;
					text-overflow: ellipsis;
				}
				.product-btn {
					font-weight: 400;
					font-size: 12px;
					color: #1d67e8;
					line-height: 17px;
					position: absolute;
					right: 16px;
					top: 50%;
					transform: translateY(-50%);
				}
				&:hover,
				&:focus {
					color: #fff;
					.product-img {
						visibility: initial;
					}
				}
			}
		}
	}
}
@media screen and (max-width: 1200px) {
}
@media screen and (max-width: 992px) {
}
@media screen and (max-width: 768px) {
	.productCenter-warp {
		background-color: #ffffff;
		margin-bottom: 26px;
		padding: 0;
		.title {
			margin-bottom: 24px;
			.title-text {
				font-weight: 600;
				font-size: 24px;
				color: #333333;
				line-height: 24px;
			}
			.title-desc {
				font-size: 12px;
				line-height: 14px;
				i {
					font-size: 12px;
				}
			}
		}
	}
}
@media screen and (max-width: 520px) {
}
</style>
