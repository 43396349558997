<!-- 首页-行业解决方案 -->
<template>
	<div class="mobileIndustrySolutions-warp">
		<div class="tag-container">
			<el-tabs v-model="activeName">
				<el-tab-pane
					v-for="(tag, index) in fieldList"
					:key="index"
					:name="index.toString()"
				>
					<template slot="label">
						<span
							class="tag-label"
							@click="_getHomePlanList(tag.id)"
							>{{ tag.briefName }}</span
						>
					</template>
				</el-tab-pane>
			</el-tabs>
		</div>
		<div class="swiper-container">
			<div class="swiper-wrapper">
				<div
					class="swiper-slide scene-item"
					v-for="(scene, indez) in fieldList"
					:key="indez"
					v-lazy:background-image="scene.coverImg"
					@click="handleClick(indez)"
				>
					<div class="scene-item-shade">
						<div class="scene-item-content">
							<p class="quote">“</p>
							<p class="name">{{ scene.name }}</p>
							<p class="describes">{{ scene.describes }}</p>
							<div class="btn">查看详情</div>
						</div>
						<div class="card-list">
							<p
								v-for="item in solutionList"
								:key="item.id"
								@click="handleSolution(item.id)"
							>
								<i class="iconfont icon-yuanquan"></i
								>{{ item.name }}
							</p>
						</div>
					</div>
				</div>
			</div>
			<div class="pagination"></div>
		</div>
	</div>
</template>

<script>
// 引入js
import Swiper from 'swiper'
// 引入css
import 'swiper/css/swiper.min.css'
import { getHomeFieldList, getHomePlanList } from '@/network/home/index'
export default {
	name: 'MobileIndustrySolutions',
	data() {
		return {
			swiperInstance: null,
			activeName: '0',
			solutionList: [],
			fieldList: [],
		}
	},
	props: {
		// visibleTags: {
		// 	type: Array,
		// 	default: () => [],
		// },
	},
	components: {},
	computed: {
		visibleTagImg() {
			let num = parseInt(this.activeName)
			return this.fieldList[num]?.coverImg
		},
		visibleTagName() {
			let num = parseInt(this.activeName)
			return this.fieldList[num]?.name
		},
		visibleTagDesc() {
			let num = parseInt(this.activeName)
			return this.fieldList[num]?.describes
		},
	},
	watch: {
		activeName(newVal) {
			const newIndex = parseInt(newVal)
			this.updateSwiperIndex(newIndex)
		},
		'swiperInstance.realIndex'(newVal) {
			this.updateActiveName(newVal)
		},
	},
	created() {
		this._getHomeFieldList()
		setTimeout(() => {
			this.myswiper()
		}, 500)
	},
	mounted() {},
	methods: {
		handleClick(i) {
			let dom = document.getElementsByClassName('scene-item')[i]

			dom.classList.toggle('showPlan')
		},
		updateSwiperIndex(index) {
			if (this.swiperInstance) {
				this.swiperInstance.slideTo(index)
			}
		},
		updateActiveName(index) {
			this.activeName = index.toString()
			let id = this.fieldList[index].id
			this._getHomePlanList(id)
		},
		myswiper: function () {
			let _this = this
			_this.swiperInstance = new Swiper('.swiper-container', {
				pagination: '.pagination',
				paginationClickable: true,
				slidesPerView: 'auto',
				allowTouchMove: true, //是否允许触摸滑动
				on: {
					slideChangeTransitionEnd: function () {
						_this.updateActiveName(this.activeIndex)
					},
					//   slideChange: () => {
					//   this.updateActiveName(this.swiperInstance.realIndex);
					// },
				},
			})
		},
		handleSolution(id) {
			//之后用id
			if (id) {
				this.$router.push({
					path: '/solution',
					query: {
						id,
					},
				})
			}
		},
		_getHomeFieldList() {
			getHomeFieldList().then((res) => {
				if (res.data.code == 200) {
					this.fieldList = res.data.data.list.map((item) => {
						let obj = Object.assign(item, {
							coverImg: window.baseUrl + item.coverImg,
						})
						return obj
					})
					this._getHomePlanList(res.data.data.list[0].id)
				}
			})
		},
		_getHomePlanList(id) {
			getHomePlanList(id).then((res) => {
				if (res.data.code == 200) {
					const { list } = res.data.data
					// this.solutionList = list.map((item) => {
					// 	let obj = Object.assign(item, {
					// 		coverImg: window.baseUrl + item.coverImg,
					// 	})
					// 	return obj
					// })
					this.solutionList = list
				}
			})
		},
	},

	destroyed() {},
}
</script>
<style lang="scss" scoped>
.mobileIndustrySolutions-warp {
	width: calc(100% - 24px);
	height: auto;
	padding: 0 12px;
	.tag-container {
		.tag-label {
			font-size: 14px;
			line-height: 20px;
		}
		::v-deep .el-tabs__nav-next,
		::v-deep .el-tabs__nav-prev {
			line-height: 42px;
			&:hover {
				color: #1d67e8;
			}
		}
	}
	.swiper-container {
		.swiper-wrapper {
			.swiper-slide {
				width: 240px;
				height: 310px;
				border-radius: 8px;
				background-size: cover;
				background-repeat: no-repeat;
				background-position: center center;
				margin-right: 13px;
				.scene-item-shade {
					width: 100%;
					height: 100%;
					background: linear-gradient(
						89deg,
						rgba(15, 21, 35, 0.65) 0%,
						rgba(15, 21, 35, 0) 100%
					);
					border-radius: 8px;
				}
				.scene-item-content {
					padding: 25px 20px 25px 25px;
					.quote {
						font-weight: 500;
						font-size: 42px;
						color: #ffffff;
						text-align: left;
						transform: translateX(-25px);
						line-height: 1px;
						margin-top: 25px;
					}
					.name {
						font-weight: 500;
						font-size: 16px;
						color: #ffffff;
						line-height: 23px;
					}
					.describes {
						font-size: 12px;
						color: #ffffff;
						line-height: 17px;
						margin: 4px 0 16px 0;
					}
					.btn {
						width: 46px;
						height: 18px;
						background: #1d67e8;
						border-radius: 3px 3px 3px 3px;
						padding: 4px 16px;
						font-weight: 400;
						font-size: 11px;
						color: #ffffff;
						line-height: 18px;
						cursor: pointer;
					}
				}
				.card-list {
					padding: 16px 20px;
					display: none;
					p {
						font-weight: 400;
						font-size: 14px;
						color: #ffffff;
						line-height: 22px;
						margin: 8px 0;
						cursor: pointer;
						i {
							display: inline-block;
							font-size: 14px;
							color: #1d67e8;
							margin-right: 3px;
						}
					}
				}
			}
			.showPlan {
				.scene-item-content {
					display: none;
				}
				.card-list {
					display: block;
				}
			}
		}
		.pagination {
			position: absolute;
			z-index: 20;
			left: 10px;
			bottom: 10px;
		}
	}
	::v-deep .el-tabs__nav-wrap::after {
		height: 1px;
	}
}

@media screen and (max-width: 1200px) {
}
@media screen and (max-width: 992px) {
}
@media screen and (max-width: 768px) {
}
@media screen and (max-width: 520px) {
}
</style>
