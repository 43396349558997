<template>
	<div class="home">
		<div class="home-top-img">
			<home-swiper @handleTop="handleTop" />
		</div>
		<div class="container">
			<product-center />
			<industry-solutions />
			<successful-case />
			<news-info />
			<open-platform />
		</div>
	</div>
</template>

<script>
import HomeSwiper from './childCpn/HomeSwiper'
import IndustrySolutions from './childCpn/IndustrySolutions'
import ProductCenter from './childCpn/ProductCenter'
import SuccessfulCase from './childCpn/SuccessfulCase'
import NewsInfo from './childCpn/NewsInfo'
import OpenPlatform from './childCpn/OpenPlatform'
export default {
	name: 'Home',
	data() {
		return {}
	},
	watch: {},
	created() {},
	methods: {
		handleTop(path) {
			if (path) {
				this.$router.push(path)
			}
		},
	},
	components: {
		HomeSwiper,
		IndustrySolutions,
		ProductCenter,
		SuccessfulCase,
		NewsInfo,
		OpenPlatform,
	},
	computed: {},
	mounted() {},
	destroyed() {},
}
</script>
<style lang="scss" scoped>
.home {
	// font-size: 16px;
	.home-top-img {
		width: 100%;
		height: 31.7188vw;
		// margin-bottom: 4.1667vw;
		margin-top: 66px;
	}
	.container {
		position: relative;
	}
}
@media screen and (max-width: 768px) {
	.home {
		.home-top-img {
			width: 100%;
			height: 178px;
			margin-bottom: 32px;
			margin-top: 44px;
		}
	}
}
</style>
