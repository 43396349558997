<!-- 首页-公司介绍 -->
<template>
	<div
		v-if="!isMobileEnd"
		class="platform-warp"
		v-lazy:background-image="homeOpenBgImg"
	>
		<div class="container">
			<p class="title">便捷体验智能科技，快速了解捷途慧声</p>
			<p class="desc">让您快速了解并体验更多我们产品相关的能力</p>
			<el-button
				class="btn"
				type="primary"
				@click="handleClickOpen(openPlatformUrl, '_blank')"
				>开始体验</el-button
			>
		</div>
	</div>
</template>

<script>
export default {
	name: 'OpenPlatform',
	data() {
		return {
			openPlatformUrl: window.openPlatformUrl,
			homeOpenBgImg: require('@/assets/img/home/homeOpenBg.png'),
		}
	},
	watch: {},
	created() {},
	mounted() {},
	methods: {
		handleClickOpen(url, type) {
			window.open(url, type)
		},
	},
	components: {},
	computed: {
		isMobileEnd() {
			return this.$store.state.isMobileEnd
		},
	},

	destroyed() {},
}
</script>
<style lang="scss" scoped>
.platform-warp {
	width: 100%;
	height: 213px;
	// padding: 60px 0 45px 0;
	background-size: cover;
	background-repeat: no-repeat;
	background-position: center center;
	display: flex;
	justify-content: center;
	align-items: center;
	// background-image: ;
	.container {
		text-align: center;
		.title {
			font-family: Source Han Sans, Source Han Sans;
			font-weight: 600;
			font-size: 28px;
			color: #333333;
			line-height: 22px;
		}
		.desc {
			font-family: Source Han Sans, Source Han Sans;
			font-weight: 400;
			font-size: 14px;
			color: #333333;
			line-height: 22px;
			margin: 16px 0;
		}
		.btn {
			width: 98px;
			font-size: 14px;
			background: #1d67e8;
			border-color: #1d67e8;
		}
	}
}

@media screen and (max-width: 1200px) {
}
@media screen and (max-width: 992px) {
}
@media screen and (max-width: 768px) {
}
@media screen and (max-width: 520px) {
}
</style>
