<!-- 首页-新闻资讯 -->
<template>
	<div class="NewsInfo-warp">
		<div class="title">
			<span class="title-text">新闻资讯</span>
			<span class="title-desc" @click="handleMore"
				>更多资讯<i class="iconfont icon-xiala-shang-copy"></i
			></span>
		</div>
		<div class="NewsInfo-content" v-if="!isMobileEnd">
			<div class="container">
				<div class="card" v-for="i in cardList" :key="i.id">
					<div class="card-img" @click="getNewsAndTrends(i.id)">
						<img v-lazy="i.bgImg" :key="i.id" alt="新闻简述图" />
					</div>
					<div class="card-bottom" @click="getNewsAndTrends(i.id)">
						<p class="card-bottom-title">{{ i.title }}</p>
						<span class="card-bottom-time">{{
							formattedTime(i.time)
						}}</span>
					</div>
				</div>
			</div>
		</div>
		<div class="NewsInfo-768-content" v-else>
			<div class="container">
				<el-scrollbar
					style="width: calc(100vw - 0px)"
					wrap-style="overflow-x:auto;"
				>
					<div class="card-list">
						<div
							class="card"
							v-for="i in cardList"
							:key="i.id"
							@click="getNewsAndTrends(i.id)"
						>
							<div class="card-img">
								<img
									v-lazy="i.bgImg"
									:key="i.id"
									alt="新闻简述图"
								/>
							</div>
							<div class="card-bottom">
								<p class="card-bottom-title">{{ i.title }}</p>
								<span class="card-bottom-time">{{
									formattedTime(i.time)
								}}</span>
							</div>
						</div>
					</div>
				</el-scrollbar>
			</div>
		</div>
	</div>
</template>

<script>
import { getHomeNewsList } from '@/network/home/index'
export default {
	name: 'NewsInfo',
	data() {
		return {
			cardList: [],
		}
	},
	watch: {},
	created() {
		this.j_getHomeNewsList() //获取新闻
	},
	mounted() {},
	methods: {
		handleMore() {
			this.$router.push('/newsAndTrends')
		},
		//获取新闻列表
		j_getHomeNewsList() {
			return new Promise((resolve, reject) => {
				getHomeNewsList().then((res) => {
					if (res.data.code === 200) {
						let list = res.data.data.list
						this.cardList = list.map((item) => {
							let obj = Object.assign(item, {
								bgImg: window.baseUrl + item.bgImg,
							})
							return obj
						})
						resolve()
					} else {
						this.$message.warning(res.data.msg)
						reject()
					}
				})
			})
		},
		getNewsAndTrends(id) {
			if (id) {
				this.$router.push({
					path: '/newsDetails',
					query: {
						id,
					},
				})
			}
		},
		formattedTime(dateString) {
			const currentTime = new Date()
			const inputDate = new Date(dateString)

			if (isNaN(inputDate.getTime())) {
				return '无效日期'
			}

			const timeDiff = (currentTime - inputDate) / 1000

			if (timeDiff < 60 * 60 * 24) {
				return '今天'
			} else if (timeDiff < 60 * 60 * 24 * 7) {
				const days = Math.floor(timeDiff / (60 * 60 * 24))
				return `${days}天前`
			} else if (timeDiff < 60 * 60 * 24 * 30) {
				const weeks = Math.floor(timeDiff / (60 * 60 * 24 * 7))
				return `${weeks}周前`
			} else {
				const months = Math.floor(timeDiff / (60 * 60 * 24 * 30))
				return `${months}月前`
			}
		},
	},
	components: {},
	computed: {
		isMobileEnd() {
			return this.$store.state.isMobileEnd
		},
	},

	destroyed() {},
}
</script>
<style lang="scss" scoped>
.NewsInfo-warp {
	margin-bottom: 80px;
	.NewsInfo-content {
		display: flex;
		justify-content: center;
		.container {
			// width: 1190px;
			min-height: 300px;
			display: grid;
			grid-template-columns: 1fr 1fr 1fr;
			gap: 0 16px;
			grid-auto-flow: row;
			justify-content: space-between;
			.card {
				width: 386px;
				// height: 306px;
				border-radius: 16px;
				overflow: hidden;
				.card-img {
					width: 100%;
					height: 220px;
					position: relative;
					cursor: pointer;
					img {
						width: 100%;
						height: 100%;
						display: inline-block;
						object-fit: cover;
					}
				}
				.card-bottom {
					width: calc(100% - 40px);
					// height: 66px;
					padding: 20px;
					background-color: #f6f9fb;
					border-radius: 0 0 16px 16px;
					&-title {
						width: 100%;
						font-weight: 400;
						font-size: 16px;
						color: #333333;
						line-height: 16px;
						white-space: nowrap;
						overflow: hidden;
						text-overflow: ellipsis;
						margin-bottom: 12px;
					}
					&-time {
						font-weight: 400;
						font-size: 16px;
						color: #999999;
						line-height: 16px;
					}
				}
				&:hover {
					.card-bottom-title {
						color: #127bf2;
						cursor: pointer;
					}
				}
			}
		}
	}
	.NewsInfo-768-content {
		.container {
			width: 596px;
			height: 192px;
			.card-list {
				width: 596px;
				height: 200px;
				margin: 0 14px;
				display: flex;
				gap: 15px;
			}
			.card {
				width: 186px;
				height: 192px;
				.card-img {
					width: 100%;
					height: 106px;
					position: relative;
					cursor: pointer;
					border-radius: 6px 6px 0 0;
					overflow: hidden;
					img {
						width: 100%;
						height: 100%;
						display: inline-block;
						object-fit: cover;
					}
				}
				.card-bottom {
					width: 100%;
					height: 86px;
					padding: 8px 12px 0 12px;
					box-sizing: border-box;
					background-color: #f6f9fb;
					border-radius: 0 0 6px 6px !important;
					overflow: hidden;
					position: relative;
					&-title {
						font-weight: 500;
						font-size: 14px;
						color: #333333;
						line-height: 20px;
						// white-space: nowrap;
						-webkit-line-clamp: 2;
						overflow: hidden;
						text-overflow: ellipsis;
						margin-bottom: 8px;
					}
					&-time {
						display: inline-block;
						font-weight: 400;
						font-size: 12px;
						color: #999999;
						line-height: 7px;
						position: absolute;
						left: 12px;
						bottom: 16px;
					}
				}

				&:nth-child(3) {
					margin-right: 20px;
				}
			}
		}
	}
}
::v-deep .el-scrollbar__wrap {
	overflow-x: auto;
	overflow-y: hidden;
}

::v-deep .el-scrollbar__bar {
	display: none;
}
.title {
	width: 100%;
	margin-bottom: 40px;
	text-align: center;
	.title-text {
		font-size: 36px;
		font-weight: 600;
		color: #333333;
		line-height: 52px;
		display: block;
	}
	.title-desc {
		font-size: 16px;
		font-family: Source Han Sans, Source Han Sans CN;
		font-weight: 400;
		color: #2185ff;
		line-height: 22px;
		display: inline-block;
		padding-top: 24px;
		cursor: pointer;
		i {
			font-size: 16px;
			display: inline-block;
			margin-left: 4px;
		}
	}
}

@media screen and (max-width: 1200px) {
}
@media screen and (max-width: 992px) {
}
@media screen and (max-width: 768px) {
	.NewsInfo-warp {
		margin-bottom: 26px;
		.title {
			margin-bottom: 24px;
			.title-text {
				height: 24px;
				font-weight: 600;
				font-size: 24px;
				color: #333333;
				line-height: 24px;
			}
			.title-desc {
				font-size: 12px;
				line-height: 14px;
				padding-top: 12px;
				i {
					font-size: 12px;
				}
			}
		}
	}
}
@media screen and (max-width: 520px) {
}
</style>
