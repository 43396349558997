<!-- 首页-轮播图 -->
<template>
	<el-carousel :interval="5000" style="height: 100%" height="100%">
		<el-carousel-item v-for="(item, index) in swiperList" :key="index">
			<img
				v-lazy="item.bgImg"
				:key="index"
				alt="首页"
				@click="$emit('handleTop', item.content)"
			/>
		</el-carousel-item>
	</el-carousel>
</template>

<script>
import { getSwiperList } from '@/network/home/index'
export default {
	name: 'NewsInfo',
	data() {
		return {
			swiperList: [],
		}
	},
	watch: {},
	created() {},
	mounted() {
		this.j_getSwiperList() //获取首页轮播图
	},
	methods: {
		j_getSwiperList() {
			return new Promise((resolve, reject) => {
				getSwiperList().then((res) => {
					if (res.data.code === 200) {
						let dataList = res.data.data
						this.swiperList = dataList.map((item) => {
							let obj = Object.assign(item, {
								bgImg: window.baseUrl + item.bgImg,
							})
							return obj
						})
						resolve()
					} else {
						reject()
					}
				})
			})
		},
	},
	components: {},
	computed: {},

	destroyed() {},
}
</script>
<style lang="scss" scoped>
img {
	width: 100%;
	height: 100%;
	object-fit: cover;
}
::v-deep .el-carousel__indicators--horizontal {
	left: 16.9271vw;
	bottom: 2.0833vw;
	transform: none;
}
::v-deep .el-carousel__button {
	height: 4px;
	// background-color: #999999;
}
// ::v-deep .el-carousel__indicator.is-active button {
// 	background-color: #1d67e8;
// }
@media screen and (max-width: 768px) {
	::v-deep .el-carousel__indicators--horizontal {
		left: 16px;
		bottom: 25px;
		transform: none;
	}
	::v-deep .el-carousel__button {
		width: 18px;
		height: 2px;
	}
}
</style>
